import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import {
  SubHeader,
  BackgroundFade,
  Container,
  Loader,
  SubNav,
  AdventureHeader,
} from "../../components/ui";
import { Adventures } from "../../services/ultimathule-api";

import AdventureOverview from "./AdventureOverview";
import AdventureItinerary from "./AdventureItinerary";
import AdventureReviews from "./AdventureReviews";
import AdventureTrips from "./AdventureTrips";

import { Grades } from "../../services/translations";

class AdventurePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adventure: this.props.location.adventure || null,
    };
  }

  async componentWillMount() {
    if (this.state.adventure == null) {
      Adventures.get(this.props.match.params.id).then((res) => {
        const { adventure } = res.data;
        this.setState({ adventure });
      });
    }
  }

  render() {
    const { adventure } = this.state;
    if (!adventure) return <Loader />;
    let path = `/adventure/${adventure.code}`;

    return (
      <div>
        <SubHeader title={adventure.name[0]} subtitle={adventure.synopsis[0]} />
        <BackgroundFade
          image={
            adventure.images
              ? `https://res.cloudinary.com/dj0wwxv7z/${adventure.images[0]}.jpg`
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaDPf_f8yikHiS7PslGUEUdFP_8aOosdZfH0ZIdrNEsVA-1V6Kwg&s"
          }
        />
        <Container>
          <AdventureHeader adventure={adventure} grades={Grades.get()} />
          <SubNav
            items={[
              { label: "Yfirlit", link: path },
              { label: "Dagskrá ferðar", link: `${path}/itinerary` },
              { label: "Brottfarir", link: `${path}/trips` },
              { label: "Frekari Upplýsingar", url: adventure.tripnotes[0] },
              { label: "Umsagnir", link: `${path}/reviews` },
            ]}
          />
          <Switch>
            <Route
              exact
              path="/adventure/:id/"
              component={() => <AdventureOverview adventure={adventure} />}
            />
            <Route
              exact
              path="/adventure/:id/itinerary"
              component={() => <AdventureItinerary adventure={adventure} />}
            />
            <Route
              exact
              path="/adventure/:id/reviews"
              component={() => <AdventureReviews adventure={adventure} />}
            />
            <Route
              exact
              path="/adventure/:id/trips"
              component={() => <AdventureTrips adventure={adventure} />}
            />
          </Switch>
        </Container>
      </div>
    );
  }
}

export default AdventurePage;

import React from 'react';
import './ItineraryListing.scss';

const ItineraryListing = ({ day, short, long }) => (
    <div className="itineraryListing">
        <div className="titles">
            <h2>{day}</h2>
            <h3>{short}</h3>
        </div>
        <p>{long}</p>
    </div>
);

export default ItineraryListing;
import React from 'react';
import './Loader.scss';
import { Cog } from '../Icons';

const Loader = () => (
    <div className="loader">
        <img src={Cog} alt='loading' />
    </div>
);

export default Loader;
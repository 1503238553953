const allCountries = [
  { name: 'afghanistan', icelandicName: 'afganistan', code: 'af' },
  { name: 'åland islands', icelandicName: 'álandseyjar', code: 'ax' },
  { name: 'albania', icelandicName: 'albanía', code: 'al' },
  { name: 'algeria', icelandicName: 'alsír', code: 'dz' },
  { name: 'american samoa', icelandicName: 'samóaeyjar', code: 'as' },
  { name: 'andorra', icelandicName: 'andorra', code: 'ad' },
  { name: 'angola', icelandicName: 'angóla', code: 'ao' },
  { name: 'anguilla', icelandicName: 'anguilla', code: 'ai' },
  { name: 'antarctica', icelandicName: 'suðurskautslandið', code: 'aq' },
  { name: 'antigua and barbuda', icelandicName: 'antígva og barbúda', code: 'ag' },
  { name: 'argentina', icelandicName: 'argentína', code: 'ar' },
  { name: 'armenia', icelandicName: 'armenía', code: 'am' },
  { name: 'aruba', icelandicName: 'aruba', code: 'aw' },
  { name: 'australia', icelandicName: 'ástralía', code: 'au' },
  { name: 'austria', icelandicName: 'austurríki', code: 'at' },
  { name: 'azerbaijan', icelandicName: 'aserbaídsjan', code: 'az' },
  { name: 'bahamas', icelandicName: 'bahamaeyjar', code: 'bs' },
  { name: 'bahrain', icelandicName: 'barein', code: 'bh' },
  { name: 'bangladesh', icelandicName: 'bangladesh', code: 'bd' },
  { name: 'barbados', icelandicName: 'barbados', code: 'bb' },
  { name: 'belarus', icelandicName: 'hvíta-rússland', code: 'by' },
  { name: 'belgium', icelandicName: 'belgía', code: 'be' },
  { name: 'belize', icelandicName: 'belís', code: 'bz' },
  { name: 'benin', icelandicName: 'benin', code: 'bj' },
  { name: 'bermuda', icelandicName: 'bermúda', code: 'bm' },
  { name: 'bhutan', icelandicName: 'bútan', code: 'bt' },
  { name: 'bolivia', icelandicName: 'bólivía', code: 'bo' },
  { name: 'bosnia and herzegovina', icelandicName: 'bosnía og hersegóvína', code: 'ba' },
  { name: 'botswana', icelandicName: 'botsvana', code: 'bw' },
  { name: 'bouvet island', icelandicName: 'bouvet island', code: 'bv' },
  { name: 'brazil', icelandicName: 'brasilía', code: 'br' },
  { name: 'british indian ocean territory', icelandicName: 'british indian ocean territory', code: 'io' },
  { name: 'brunei darussalam', icelandicName: 'brunei darussalam', code: 'bn' },
  { name: 'bulgaria', icelandicName: 'búlgaría', code: 'bg' },
  { name: 'burkina faso', icelandicName: 'burkina faso', code: 'bf' },
  { name: 'burundi', icelandicName: 'búrúndí', code: 'bi' },
  { name: 'cambodia', icelandicName: 'kambódía', code: 'kh' },
  { name: 'cameroon', icelandicName: 'kamerún', code: 'cm' },
  { name: 'canada', icelandicName: 'kanada', code: 'ca' },
  { name: 'cape verde', icelandicName: 'cape verde', code: 'cv' },
  { name: 'cayman islands', icelandicName: 'cayman islands', code: 'ky' },
  { name: 'central african republic', icelandicName: 'mið-afríkulýðveldið', code: 'cf' },
  { name: 'chad', icelandicName: 'chad', code: 'td' },
  { name: 'chile', icelandicName: 'chile', code: 'cl' },
  { name: 'china', icelandicName: 'kína', code: 'cn' },
  { name: 'christmas island', icelandicName: 'jólaeyja', code: 'cx' },
  { name: 'cocos (keeling) islands', icelandicName: 'cocos (keeling) islands', code: 'cc' },
  { name: 'colombia', icelandicName: 'kólumbía', code: 'co' },
  { name: 'comoros', icelandicName: 'comoros', code: 'km' },
  { name: 'congo', icelandicName: 'kongó', code: 'cg' },
  { name: 'congo, the democratic republic of the', icelandicName: 'kongó, lýðveldið', code: 'cd' },
  { name: 'cook islands', icelandicName: 'cook islands', code: 'ck' },
  { name: 'costa rica', icelandicName: 'kosta ríka', code: 'cr' },
  { name: 'cote d\'ivoire', icelandicName: 'fílabeinsströndin', code: 'ci' },
  { name: 'croatia', icelandicName: 'króatía', code: 'hr' },
  { name: 'cuba', icelandicName: 'kúbu', code: 'cu' },
  { name: 'cyprus', icelandicName: 'kýpur', code: 'cy' },
  { name: 'czech republic', icelandicName: 'tékkland', code: 'cz' },
  { name: 'denmark', icelandicName: 'danmörk', code: 'dk' },
  { name: 'djibouti', icelandicName: 'djibouti', code: 'dj' },
  { name: 'dominica', icelandicName: 'dóminíka', code: 'dm' },
  { name: 'dominican republic', icelandicName: 'dóminíska lýðveldið', code: 'do' },
  { name: 'ecuador', icelandicName: 'ekvador', code: 'ec' },
  { name: 'egypt', icelandicName: 'egyptaland', code: 'eg' },
  { name: 'el salvador', icelandicName: 'el salvador', code: 'sv' },
  { name: 'equatorial guinea', icelandicName: 'miðbaugs-gínea', code: 'gq' },
  { name: 'eritrea', icelandicName: 'erítrea', code: 'er' },
  { name: 'estonia', icelandicName: 'eistland', code: 'ee' },
  { name: 'ethiopia', icelandicName: 'eþíópíu', code: 'et' },
  { name: 'falkland islands (malvinas)', icelandicName: 'falklandseyjar (malvinas)', code: 'fk' },
  { name: 'faroe islands', icelandicName: 'færeyjar', code: 'fo' },
  { name: 'fiji', icelandicName: 'fiji', code: 'fj' },
  { name: 'finland', icelandicName: 'finnland', code: 'fi' },
  { name: 'france', icelandicName: 'frakklandi', code: 'fr' },
  { name: 'french guiana', icelandicName: 'franska gvæjana', code: 'gf' },
  { name: 'french polynesia', icelandicName: 'franska pólýnesía', code: 'pf' },
  { name: 'french southern territories', icelandicName: 'franska suðurríkja', code: 'tf' },
  { name: 'gabon', icelandicName: 'gabon', code: 'ga' },
  { name: 'gambia', icelandicName: 'gambía', code: 'gm' },
  { name: 'georgia', icelandicName: 'georgia', code: 'ge' },
  { name: 'germany', icelandicName: 'þýskaland', code: 'de' },
  { name: 'ghana', icelandicName: 'gana', code: 'gh' },
  { name: 'gibraltar', icelandicName: 'gíbraltar', code: 'gi' },
  { name: 'greece', icelandicName: 'grikkland', code: 'gr' },
  { name: 'greenland', icelandicName: 'grænland', code: 'gl' },
  { name: 'grenada', icelandicName: 'grenada', code: 'gd' },
  { name: 'guadeloupe', icelandicName: 'gvadelúp', code: 'gp' },
  { name: 'guam', icelandicName: 'guam', code: 'gu' },
  { name: 'guatemala', icelandicName: 'gvatemala', code: 'gt' },
  { name: 'guernsey', icelandicName: 'guernsey', code: 'gg' },
  { name: 'guinea', icelandicName: 'gínea', code: 'gn' },
  { name: 'guinea-bissau', icelandicName: 'gínea-bissá', code: 'gw' },
  { name: 'guyana', icelandicName: 'guyana', code: 'gy' },
  { name: 'haiti', icelandicName: 'haítí', code: 'ht' },
  { name: 'heard island and mcdonald islands', icelandicName: 'heard island og mcdonald islands', code: 'hm' },
  { name: 'holy see (vatican city state)', icelandicName: 'holy see (vatíkanið)', code: 'va' },
  { name: 'honduras', icelandicName: 'hondúras', code: 'hn' },
  { name: 'hong kong', icelandicName: 'hong kong', code: 'hk' },
  { name: 'hungary', icelandicName: 'ungverjaland', code: 'hu' },
  { name: 'iceland', icelandicName: 'ísland', code: 'is' },
  { name: 'india', icelandicName: 'indland', code: 'in' },
  { name: 'indonesia', icelandicName: 'indónesía', code: 'id' },
  { name: 'iran', icelandicName: 'íran', code: 'ir' },
  { name: 'iraq', icelandicName: 'írak', code: 'iq' },
  { name: 'ireland', icelandicName: 'írland', code: 'ie' },
  { name: 'isle of man', icelandicName: 'mön', code: 'im' },
  { name: 'israel', icelandicName: 'ísrael', code: 'il' },
  { name: 'italy', icelandicName: 'ítalía', code: 'it' },
  { name: 'jamaica', icelandicName: 'jamaíka', code: 'jm' },
  { name: 'japan', icelandicName: 'japan', code: 'jp' },
  { name: 'jersey', icelandicName: 'jersey', code: 'je' },
  { name: 'jordan', icelandicName: 'jórdanía', code: 'jo' },
  { name: 'kazakhstan', icelandicName: 'kasakstan', code: 'kz' },
  { name: 'kenya', icelandicName: 'kenýa', code: 'ke' },
  { name: 'kiribati', icelandicName: 'kiribati', code: 'ki' },
  { name: 'north korea', icelandicName: 'norður kórea', code: 'kp' },
  { name: 'south korea', icelandicName: 'suður kórea', code: 'kr' },
  { name: 'kuwait', icelandicName: 'kúveit', code: 'kw' },
  { name: 'kyrgyzstan', icelandicName: 'kirgisistan', code: 'kg' },
  { name: 'lao people\'s democratic republic', icelandicName: 'lýðveldið laos fólks', code: 'la' },
  { name: 'latvia', icelandicName: 'lettland', code: 'lv' },
  { name: 'lebanon', icelandicName: 'líbanon', code: 'lb' },
  { name: 'lesotho', icelandicName: 'lesótó', code: 'ls' },
  { name: 'liberia', icelandicName: 'líbería', code: 'lr' },
  { name: 'libya', icelandicName: 'líbía', code: 'ly' },
  { name: 'liechtenstein', icelandicName: 'liechtenstein', code: 'li' },
  { name: 'lithuania', icelandicName: 'litháen', code: 'lt' },
  { name: 'luxembourg', icelandicName: 'lúxemborg', code: 'lu' },
  { name: 'macao', icelandicName: 'macao', code: 'mo' },
  { name: 'macedonia, the former yugoslav republic of', icelandicName: 'makedónía, fyrrum júgóslavneska lýðveldið', code: 'mk' },
  { name: 'madagascar', icelandicName: 'madagaskar', code: 'mg' },
  { name: 'malawi', icelandicName: 'malaví', code: 'mw' },
  { name: 'malaysia', icelandicName: 'malasía', code: 'my' },
  { name: 'maldives', icelandicName: 'maldíveyjar', code: 'mv' },
  { name: 'mali', icelandicName: 'mali', code: 'ml' },
  { name: 'malta', icelandicName: 'möltu', code: 'mt' },
  { name: 'marshall islands', icelandicName: 'marshall islands', code: 'mh' },
  { name: 'martinique', icelandicName: 'martinique', code: 'mq' },
  { name: 'mauritania', icelandicName: 'máritanía', code: 'mr' },
  { name: 'mauritius', icelandicName: 'máritíus', code: 'mu' },
  { name: 'mayotte', icelandicName: 'mayotte', code: 'yt' },
  { name: 'mexico', icelandicName: 'mexíkó', code: 'mx' },
  { name: 'micronesia, federated states of', icelandicName: 'míkrónesía, sambandsríkin', code: 'fm' },
  { name: 'moldova, republic of', icelandicName: 'moldavía, lýðveldið', code: 'md' },
  { name: 'monaco', icelandicName: 'mónakó', code: 'mc' },
  { name: 'mongolia', icelandicName: 'mongólía', code: 'mn' },
  { name: 'montserrat', icelandicName: 'montserrat', code: 'ms' },
  { name: 'morocco', icelandicName: 'marokkó', code: 'ma' },
  { name: 'mozambique', icelandicName: 'mósambík', code: 'mz' },
  { name: 'myanmar', icelandicName: 'mjanmar', code: 'mm' },
  { name: 'namibia', icelandicName: 'namibía', code: 'na' },
  { name: 'nauru', icelandicName: 'nauru', code: 'nr' },
  { name: 'nepal', icelandicName: 'nepal', code: 'np' },
  { name: 'netherlands', icelandicName: 'hollandi', code: 'nl' },
  { name: 'netherlands antilles', icelandicName: 'hollensku antilles-eyjar', code: 'an' },
  { name: 'new caledonia', icelandicName: 'nýja kaledónía', code: 'nc' },
  { name: 'new zealand', icelandicName: 'nýja sjáland', code: 'nz' },
  { name: 'nicaragua', icelandicName: 'níkaragva', code: 'ni' },
  { name: 'niger', icelandicName: 'níger', code: 'ne' },
  { name: 'nigeria', icelandicName: 'nígería', code: 'ng' },
  { name: 'niue', icelandicName: 'niue', code: 'nu' },
  { name: 'norfolk island', icelandicName: 'norfolk island', code: 'nf' },
  { name: 'northern mariana islands', icelandicName: 'norður mariana islands', code: 'mp' },
  { name: 'norway', icelandicName: 'noregur', code: 'no' },
  { name: 'oman', icelandicName: 'óman', code: 'om' },
  { name: 'pakistan', icelandicName: 'pakistan', code: 'pk' },
  { name: 'palau', icelandicName: 'palau', code: 'pw' },
  { name: 'palestinian territory, occupied', icelandicName: 'palestínumanna, upptekin', code: 'ps' },
  { name: 'panama', icelandicName: 'panama', code: 'pa' },
  { name: 'papua new guinea', icelandicName: 'papúa nýja-gínea', code: 'pg' },
  { name: 'paraguay', icelandicName: 'paragvæ', code: 'py' },
  { name: 'peru', icelandicName: 'perú', code: 'pe' },
  { name: 'philippines', icelandicName: 'filippseyjar', code: 'ph' },
  { name: 'pitcairn', icelandicName: 'pitcairn', code: 'pn' },
  { name: 'poland', icelandicName: 'pólland', code: 'pl' },
  { name: 'portugal', icelandicName: 'portúgal', code: 'pt' },
  { name: 'puerto rico', icelandicName: 'púertó ríkó', code: 'pr' },
  { name: 'qatar', icelandicName: 'katar', code: 'qa' },
  { name: 'reunion', icelandicName: 'reunion', code: 're' },
  { name: 'romania', icelandicName: 'rúmenía', code: 'ro' },
  { name: 'russia', icelandicName: 'rússland', code: 'ru' },
  { name: 'rwanda', icelandicName: 'rwanda', code: 'rw' },
  { name: 'saint helena', icelandicName: 'sankti helena', code: 'sh' },
  { name: 'saint kitts and nevis', icelandicName: 'sankti kristófer og nevis', code: 'kn' },
  { name: 'saint lucia', icelandicName: 'sankti lúsía', code: 'lc' },
  { name: 'saint pierre and miquelon', icelandicName: 'saint pierre og miquelon', code: 'pm' },
  { name: 'saint vincent and the grenadines', icelandicName: 'sankti vinsent og grenadíneyjar', code: 'vc' },
  { name: 'samoa', icelandicName: 'samóa', code: 'ws' },
  { name: 'san marino', icelandicName: 'san marínó', code: 'sm' },
  { name: 'sao tome and principe', icelandicName: 'sao tome og principe', code: 'st' },
  { name: 'saudi arabia', icelandicName: 'sádí-arabía', code: 'sa' },
  { name: 'senegal', icelandicName: 'senegal', code: 'sn' },
  { name: 'serbia and montenegro', icelandicName: 'serbía og svartfjallaland', code: 'cs' },
  { name: 'seychelles', icelandicName: 'seychelles', code: 'sc' },
  { name: 'sierra leone', icelandicName: 'sierra leone', code: 'sl' },
  { name: 'singapore', icelandicName: 'singapúr', code: 'sg' },
  { name: 'slovakia', icelandicName: 'slóvakía', code: 'sk' },
  { name: 'slovenia', icelandicName: 'slóvenía', code: 'si' },
  { name: 'solomon islands', icelandicName: 'salómonseyjar', code: 'sb' },
  { name: 'somalia', icelandicName: 'sómalía', code: 'so' },
  { name: 'south africa', icelandicName: 'suður afríka', code: 'za' },
  { name: 'south georgia and the south sandwich islands', icelandicName: 'suður georgía og suður sandwich islands', code: 'gs' },
  { name: 'spain', icelandicName: 'spánn', code: 'es' },
  { name: 'sri lanka', icelandicName: 'sri lanka', code: 'lk' },
  { name: 'sudan', icelandicName: 'súdan', code: 'sd' },
  { name: 'suriname', icelandicName: 'súrínam', code: 'sr' },
  { name: 'svalbard and jan mayen', icelandicName: 'svalbarði og jan mayen', code: 'sj' },
  { name: 'swaziland', icelandicName: 'svasíland', code: 'sz' },
  { name: 'sweden', icelandicName: 'svíþjóð', code: 'se' },
  { name: 'switzerland', icelandicName: 'sviss', code: 'ch' },
  { name: 'syria', icelandicName: 'sýrland', code: 'sy' },
  { name: 'taiwan, province of china', icelandicName: 'taívan, province of china', code: 'tw' },
  { name: 'tajikistan', icelandicName: 'tadsjikistan', code: 'tj' },
  { name: 'tanzania, united republic of', icelandicName: 'tansanía, sameinuðu þjóðanna', code: 'tz' },
  { name: 'thailand', icelandicName: 'taíland', code: 'th' },
  { name: 'timor-leste', icelandicName: 'austur-tímor', code: 'tl' },
  { name: 'togo', icelandicName: 'að fara', code: 'tg' },
  { name: 'tokelau', icelandicName: 'tokelau', code: 'tk' },
  { name: 'tonga', icelandicName: 'tonga', code: 'to' },
  { name: 'trinidad and tobago', icelandicName: 'trínidad og tóbagó', code: 'tt' },
  { name: 'tunisia', icelandicName: 'túnis', code: 'tn' },
  { name: 'turkey', icelandicName: 'tyrkland', code: 'tr' },
  { name: 'turkmenistan', icelandicName: 'túrkmenistan', code: 'tm' },
  { name: 'turks and caicos islands', icelandicName: 'turks og caicos islands', code: 'tc' },
  { name: 'tuvalu', icelandicName: 'tuvalu', code: 'tv' },
  { name: 'uganda', icelandicName: 'úganda', code: 'ug' },
  { name: 'ukraine', icelandicName: 'úkraína', code: 'ua' },
  { name: 'united arab emirates', icelandicName: 'sameinuðu arabísku furstadæmin', code: 'ae' },
  { name: 'united kingdom', icelandicName: 'bretland', code: 'gb' },
  { name: 'united states', icelandicName: 'bandaríkin', code: 'us' },
  { name: 'united states minor outlying islands', icelandicName: 'minor outlying islands í bandaríkjunum', code: 'um' },
  { name: 'uruguay', icelandicName: 'úrúgvæ', code: 'uy' },
  { name: 'uzbekistan', icelandicName: 'úsbekistan', code: 'uz' },
  { name: 'vanuatu', icelandicName: 'vanúatú', code: 'vu' },
  { name: 'venezuela', icelandicName: 'venesúela', code: 've' },
  { name: 'vietnam', icelandicName: 'víetnam', code: 'vn' },
  { name: 'virgin islands, british', icelandicName: 'virgin islands, british', code: 'vg' },
  { name: 'virgin islands, u.s.', icelandicName: 'virgin islands, u.s.', code: 'vi' },
  { name: 'wallis and futuna', icelandicName: 'wallis og futuna', code: 'wf' },
  { name: 'western sahara', icelandicName: 'vestur-sahara', code: 'eh' },
  { name: 'yemen', icelandicName: 'jemen', code: 'ye' },
  { name: 'zambia', icelandicName: 'sambía', code: 'zm' },
  { name: 'zimbabwe', icelandicName: 'simbabve', code: 'zw' }
]

export const translateToIs = expression => {
  let term = allCountries.find(a => a.name.toLowerCase() === expression.toLowerCase());
  return term ? term.icelandicName : false;
}

export const get = () => allCountries;
import React, { Component } from 'react';
import './SearchBar.scss';

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false,
            value: '',
            caret: 0,
        }
    }

    handleSelect = item => {
        const { select } = this.props;
        select(item);
        this.setState({ value: '' });
    }

    // handle backspace!!


    render() {
        const { label, tag, placeholder, data, select, deSelect, values } = this.props;
        const { focus, value } = this.state;

        const suggestions = focus && (value.length > 0) &&
            data.filter(d => {
                return (d.name.includes(value.toLowerCase()) || d.icelandicName.includes(value.toLowerCase()))
            }).slice(0, 10)
                .map((d, i) =>
                    <p key={i} onMouseDown={e => select ? this.handleSelect(d.name) : this.setState({ value: d.name, focus: false })}>
                        {d.icelandicName}
                    </p>
                )

        return (
            <label
                className="searchBar"
                htmlFor={tag}
                onFocus={e => this.setState({ focus: true })}
                onBlur={e => this.setState({ focus: false })}
            >
                {label}
                <div className="inputContainer">
                    {
                        values.map(val =>
                            <div className="selected" onMouseDown={e => deSelect && deSelect(val)}>
                                {data.find(d => d.name === val.toLowerCase()) && data.find(d => d.name === val.toLowerCase()).icelandicName}
                            </div>
                        )
                    }
                    {
                        values.length < 3 &&
                        <input
                            type="text"
                            id={tag}
                            name={tag}
                            value={value}
                            placeholder={values.length ? '...' : placeholder}
                            onChange={e => this.setState({ value: e.target.value })}
                            autoComplete={'off'}
                        />
                    }
                </div>
                {suggestions && <div className="autoComplete">{suggestions}</div>}

            </label>
        )
    }
}

export default SearchBar;
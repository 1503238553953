import React from 'react';
import './Section.scss';

const Section = ({ title, img, right, center, children }) => (
    <div className={`section ${right ? 'right' : ''} ${center ? 'center' : ''}`}>
        <div className="content">
            <h2>{title}</h2>
            {children}
        </div>
        {img && <img className='sectionImage' src={img} alt={title} />}
    </div>
);

export default Section;
import React from 'react';
import { Link } from 'react-router-dom';
import './Album.scss';

const Album = ({ adventures, external }) => (
    <div className="album">
        {
            adventures.slice(0, 8).map((adventure, i) =>
                external ?
                    <a href={adventure.link} target="blank" className="albumItem" style={{ backgroundImage: `url(${adventure.img})` }} key={i}>
                        <div>
                            {
                                (adventure.title || adventure.description) &&
                                <div className="albumHeader">
                                    <h3>{adventure.title}</h3>
                                    <p>{adventure.description}</p>
                                </div>
                            }
                        </div>
                    </a>
                    :
                    <Link to={adventure.link} className="albumItem" style={{ backgroundImage: `url(${adventure.img})` }} key={i}>
                        <div>
                            {
                                (adventure.title || adventure.description) &&
                                <div className="albumHeader">
                                    <h3>{adventure.title}</h3>
                                    <p>{adventure.description}</p>
                                </div>
                            }
                        </div>
                    </Link>
            )
        }
    </div>
);

export default Album;
import React from 'react';
import './AdventureHeader.scss';

const AdventureHeader = ({ adventure, grades }) => (
    <div className='adventureHeader'>
        <div className="element">
            <span>Ferðakóði</span>
            <p>{adventure.code}</p>
        </div>
        <div className="element">
            <span>Erfiðleikastig</span>
            <p>{adventure.grade[0] ? grades.findIndex(g => g.label === adventure.grade[0]) : '0'}</p>
        </div>
        <div className="element">
            <span>Hópastærð</span>
            <p>{`${adventure.groupsize[0].min} - ${adventure.groupsize[0].max}`}</p>
        </div>
        <div className="element">
            <span>Lágmarksaldur</span>
            <p>{adventure.min_age[0]} ára</p>
        </div>
        <div className="element">
            <span>Lengd ferðar</span>
            <p>{adventure.holidaytype[1] ? adventure.holidaytype[1].duration[0].period : adventure.holidaytype[0].duration[0].period} dagar</p>
        </div>
        <div className="element">
            <span>Verð {adventure.prices.lowestFlight && <b style={{fontSize: 12, opacity: 0.5}}> (með flugi frá london)</b>}</span>
            <p>{adventure.prices.lowestFlight ? '£' + Math.round((adventure.prices.lowestFlight)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : adventure.prices.lowestLand ? '£' + Math.round((adventure.prices.lowestLand)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 'óuppgefið'}</p>
        </div>
    </div>
);

export default AdventureHeader;
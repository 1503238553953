import React, { Component } from 'react';
import * as Analytics from '../services/Analytics';

// UI
import {
    SubHeader,
    Container,
    AdventureList,
    Button,
    Loader,
    SearchBar,
    SearchInput,
    QuerySearch,
} from '../components/ui';
import { Grid, List } from '../components/ui/Icons';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
// Services
import { Adventures } from '../services/ultimathule-api';
import { Activities, Countries, Continents, Grades } from '../services/translations';
import axios from 'axios';

class QueryPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contentLoading: false,
            initialLoading: true,
            adventures: [],

            search: '',
            page: 0,
            pageSize: 9,
            count: 0,
            gbp: null,
            view: 0,

            regions: [],
            activities: [],

            flightOnly: false,
            landOnly: false,

            minLength: 3,
            maxLength: 28,
            order: '',
            orderQuery: { 'name': 1 }
        }
    }

    componentDidMount() {
        Analytics.logPageView()
    }

    componentWillMount() {
        this.load();

        axios.get('https://apis.is/currency/arion')
            .then(res => {
                this.setState({ gbp: res.data.results.find(c => c.shortName === 'GBP').value })
            })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.search === nextProps.location.search) return;
        this.load(nextProps);
    }

    load(nextProps) {
        const params = new URLSearchParams(nextProps ? nextProps.location.search : this.props.location.search);
        let regions = params.get('regions');
        let activities = params.get('activities');
        let search = params.get('search');
        let pageSize = params.get('pageSize');
        let difficulty = params.get('difficulty');
        let flightLimit = params.get('flightLimit');

        if (regions) regions = regions.split(',');
        if (activities) activities = activities.split(',');
        difficulty = difficulty ? JSON.parse(difficulty) : { min: 0, max: 10 };
        console.log(flightLimit);
        this.setState({
            contentLoading: false,
            initialLoading: true,
            adventures: [],

            regions,
            activities,
            search,

            page: 0,
            pageSize,
            count: 0,
            view: 0,
            order: '',
            orderQuery: { 'name': 1 },
            difficulty,
            flightLimit: flightLimit || null,
            landOnly: (flightLimit === 'land'),
            flightOnly: (flightLimit === 'flight'),
        }, () => {
            this.loadAdventures();
        })
    }

    loadMore() {
        const { page } = this.state;
        this.setState({ contentLoading: true, page: page + 1 }, () => this.loadAdventures())
    }

    loadAdventures() {
        const { regions, activities, search, count, page, pageSize, adventures, orderQuery, difficulty, flightLimit } = this.state;
        this.setState({ contentLoading: true });

        Adventures.query({ regions, activities, search }, page, pageSize, orderQuery, Grades.getRange(difficulty.min, difficulty.max).map(d => d.label), flightLimit)
            .then(res => {
                const newAdventures = res.data.adventures;
                this.setState({
                    adventures: adventures.concat(newAdventures),
                    page: page,
                    count: page ? count : res.data.count,
                    contentLoading: false,
                    initialLoading: false,
                });
            })
            .catch(err => {
                console.log(err);
            })
    }

    addRegion = item => {
        const params = new URLSearchParams(this.props.location.search);
        let r = params.get('regions');
        r = r ? r.split(',') : [];
        r.push(item);
        params.set('regions', r.join(','));
        this.props.history.push(`leit?${params.toString()}`);
    }
    removeRegion = item => {
        const params = new URLSearchParams(this.props.location.search);
        let r = params.get('regions').split(',');
        r = r.filter(p => p !== item);
        params.set('regions', r.join(','));
        this.props.history.push(`leit?${params.toString()}`);
    }

    addActivity = item => {
        const params = new URLSearchParams(this.props.location.search);
        let r = params.get('activities');
        r = r ? r.split(',') : [];
        r.push(item);
        params.set('activities', r.join(','));
        this.props.history.push(`leit?${params.toString()}`);
    }
    removeActivity = item => {
        const params = new URLSearchParams(this.props.location.search);
        let r = params.get('activities').split(',');
        r = r.filter(p => p !== item);
        params.set('activities', r.join(','));
        this.props.history.push(`leit?${params.toString()}`);
    }

    updateSearch = search => this.setState({ search });

    search = () => {
        const { search } = this.state;
        const params = new URLSearchParams(this.props.location.search);
        if (search) {
            params.set('search', search);
        } else {
            params.delete('search');
        }
        this.props.history.push(`leit?${params.toString()}`);
    }

    updateDifficultyRange = () => {
        const params = new URLSearchParams(this.props.location.search);
        params.set('difficulty', JSON.stringify(this.state.difficulty));
        this.props.history.push(`leit?${params.toString()}`);
    }

    handleOrderChange = e => {
        let orderQuery;
        const order = e.target.value;
        switch (order) {
            case 'a':
                orderQuery = { 'name': 1 }
                break;
            case 'ar':
                orderQuery = { 'name': -1 }
                break;
            case 'p':
                orderQuery = this.state.landOnly ? { 'prices.lowestLand': -1, 'prices.lowestFlight': -1 } : { 'prices.lowestFlight': -1, 'prices.lowestLand': -1 }
                break;
            case 'pr':
                orderQuery = this.state.landOnly ? { 'prices.lowestLand': 1, 'prices.lowestFlight': 1 } : { 'prices.lowestFlight': 1, 'prices.lowestLand': 1 }
                break;
            case 'l':
                orderQuery = { 'adventure.holidaytype.duration.period': 1 };
                break;
            case 'lr':
                orderQuery = { 'adventure.holidaytype.duration.period': -1 };
                break;
            default:
                break;
        }

        this.setState({
            order,
            orderQuery,
            page: 0,
            count: 0,
            adventures: []
        }, () => this.loadAdventures())
    }

    handlePageSizeChange = e => {
        const { pageSize } = this.state;
        const params = new URLSearchParams(this.props.location.search);
        if (e.target.value !== 9) {
            params.set('pageSize', e.target.value);
        } else {
            params.delete('pageSize');
        }
        this.props.history.push(`leit?${params.toString()}`);
    }

    toggleFlightOnly = () => {
        this.setState({ flightOnly: !this.state.flightOnly, landOnly: false }, () => {
            const params = new URLSearchParams(this.props.location.search);
            params.set('flightLimit', this.state.flightOnly ? 'flight' : this.state.landOnly ? 'land' : '');
            this.props.history.push(`leit?${params.toString()}`);
        });
    }

    toggleLandOnly = () => {
        this.setState({ landOnly: !this.state.landOnly, flightOnly: false }, () => {
            const params = new URLSearchParams(this.props.location.search);
            params.set('flightLimit', this.state.flightOnly ? 'flight' : this.state.landOnly ? 'land' : '');
            this.props.history.push(`leit?${params.toString()}`);
        });
    }


    render() {
        const {
            search,
            regions,
            activities,
            adventures,
            count,
            page,
            pageSize,
            gbp,
            view,
            order,
            difficulty,
            contentLoading,
            initialLoading,
            minLength,
            maxLength,
            flightOnly,
            landOnly,
        } = this.state;
        if (initialLoading) return <Loader />;
        let title = 'Leit';

        let r = regions || [];
        let a = activities || [];

        if (r.length + a.length === 1) {
            title = r.length ? (Countries.translateToIs(r[0]) || Continents.translateToIs(r[0])) : a.length ? Activities.translateToIs(a[0]) : 'Leit';
        } else if (r.length + a.length === 0 && !search) {
            title = 'Allar ferðir'
        }
        console.log(minLength, maxLength);
        return (
            <div>
                <SubHeader
                    title={title}
                    subtitle={count ? `${count} slíkar ferðir standa til boða akkurat núna!` : 'Því miður fundust engar slíkar ferðir'}
                />
                <Container>
                    <QuerySearch>
                        <SearchInput
                            tag={'name/code'}
                            label={'Leitaðu að þínu ævintýri...'}
                            placeholder={'Nafn ferðar eða ferðakóði'}
                            value={this.state.search}
                            onChange={this.updateSearch.bind(this)}
                            onSubmit={this.search.bind(this)}
                        />
                        <SearchBar
                            tag={'destination'}
                            label={'Lönd og heimsálfur...'}
                            placeholder={'Nýja Sjáland, Afríka, Austurríki..'}
                            data={Countries.get().concat(Continents.get())}
                            values={r}
                            select={this.addRegion.bind(this)}
                            deSelect={this.removeRegion.bind(this)}
                        />
                        <SearchBar
                            tag={'activity'}
                            label={'Tegund ferðar'}
                            placeholder={'Klifur, göngur, safari, dýr..'}
                            data={Activities.get()}
                            values={a}
                            select={this.addActivity.bind(this)}
                            deSelect={this.removeActivity.bind(this)}
                        />
                    </QuerySearch>
                    <div style={{ marginRight: '15px', marginBottom: '10px', display: 'inline-block', padding: '10px 20px', backgroundColor: 'rgba(0,0,0,0.01)' }}>
                        <b>Raða eftir:</b>
                        <label htmlFor="">
                            <select name="sortBy" id="" style={{ border: 'none' }} onChange={this.handleOrderChange}>
                                <option value="a" selected={"a" === order}>Stafrófsröð</option>
                                <option value="ar" selected={"ar" === order}>Öfug Stafrófsröð</option>
                                <option value="p" selected={"p" === order}>Verð ( Hæst fyrst )</option>
                                <option value="pr" selected={"pr" === order}>Verð ( Lægst fyrst )</option>
                                {/* <option value="l" selected={"l" === order}>Lengd Ferðar ( Lengst fyrst )</option>
                                <option value="lr" selected={"lr" === order}>Lengd Ferðar ( Styðst fyrst )</option> */}
                            </select>
                        </label>
                    </div>
                    <div style={{ marginRight: '15px', marginBottom: '10px', display: 'inline-block', padding: '10px 20px', backgroundColor: 'rgba(0,0,0,0.01)' }}>
                        <b>Sýna</b>
                        <label htmlFor="">
                            <select name="pageSize" id="" style={{ border: 'none' }} onChange={this.handlePageSizeChange}>
                                <option value="9" selected={"9" === pageSize}>9</option>
                                <option value="18" selected={"18" === pageSize}>18</option>
                                <option value="all" selected={"all" === pageSize}>Allar</option>
                            </select>
                        </label>
                        <b>ferðir á síðu</b>
                    </div>
                    <div style={{ marginRight: '15px', marginBottom: '10px', display: 'inline-block', padding: '10px 20px', backgroundColor: 'rgba(0,0,0,0.01)' }}>
                        <b>Erfiðleikastig á bilinu: </b>
                        <div style={{ display: 'inline-block', width: '100px', marginLeft: '20px' }}>
                            <InputRange
                                maxValue={10}
                                minValue={0}
                                value={difficulty}
                                onChange={difficulty => this.setState({ difficulty })}
                                onChangeComplete={e => this.updateDifficultyRange()}
                            />
                        </div>
                    </div>
                    <div style={{ marginRight: '15px', marginBottom: '10px', display: 'inline-block', padding: '10px 15px', backgroundColor: 'rgba(0,0,0,0.01)' }}>
                        <label htmlFor="flightonly" style={{ marginRight: '5px' }}>
                            <input
                                id="flightonly"
                                type="checkbox"
                                checked={flightOnly}
                                onChange={e => this.toggleFlightOnly()}
                            />
                            <b>með flugi</b>
                        </label>
                        <label htmlFor="landonly">
                            <input
                                id="landonly"
                                type="checkbox"
                                checked={landOnly}
                                onChange={e => this.toggleLandOnly()}
                            />
                            <b>án flugs</b>
                        </label>
                    </div>
                    {/* <div style={{ marginLeft: '50px', display: 'inline-block' }}>
                        <b style={{ marginLeft: '50px' }}>Lengd ferðar frá: </b>
                        <input
                            type="text"
                            max={28}
                            min={3}
                            value={minLength}
                            type='number'
                            style={{ width: '40px' }}
                            onChange={e => this.setState({ minLength: (Number(e.target.value) < Number(maxLength)) ? e.target.value : maxLength })}
                        />
                        <b>til:</b>
                        <input
                            type="text"
                            max={28}
                            min={3}
                            value={maxLength}
                            type='number'
                            style={{ width: '40px' }}
                            onChange={e => this.setState({ maxLength: (Number(e.target.value) > Number(minLength)) ? e.target.value : minLength })}
                        />
                        <b>dagar.</b>
                    </div> */}
                    <img src={List} style={{ width: '20px', float: 'right', marginLeft: '10px', marginBottom: '20px', opacity: view ? 1 : 0.5, cursor: 'pointer' }} alt="" onClick={e => this.setState({ view: 1 })} />
                    <img src={Grid} style={{ width: '20px', float: 'right', marginLeft: '10px', marginBottom: '20px', opacity: view ? 0.5 : 1, cursor: 'pointer' }} alt="" onClick={e => this.setState({ view: 0 })} />
                    <AdventureList adventures={adventures} currency={gbp} view={view} landOnly={landOnly} />
                    {
                        (page < Math.floor(count / 9) && count !== 9) &&
                        <Button onClick={this.loadMore.bind(this)} loading={contentLoading}>
                            Sækja fleiri ferðir
                        </Button>
                    }
                </Container>
            </div>
        )
    }
}

export default QueryPage;
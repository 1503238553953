import React from 'react';
import './SearchButton.scss';
import { Link } from 'react-router-dom';

const SearchButton = ({ link, children }) => (
    <Link to={link} className='searchButtonContainer'>
        <button type="click">{children}</button>
    </Link>
);

export default SearchButton;
const allContinents = [
    { name: 'africa', icelandicName: 'afríka' },
    { name: 'europe', icelandicName: 'evrópa' },
    { name: 'asia', icelandicName: 'asía' },
    { name: 'north america', icelandicName: 'norður ameríka' },
    { name: 'south america', icelandicName: 'suður ameríka' },
    { name: 'australia', icelandicName: 'ástralía' },
    { name: 'antarctica', icelandicName: 'suðurskautslandið' },
]

export const translateToIs = expression => {
    let term = allContinents.find(a => a.name.toLowerCase() === expression.toLowerCase());
    return term ? term.icelandicName : expression;
}

export const get = () => allContinents;
import React from "react";
import "./Hero.scss";

const Hero = ({ message, image, offer }) => (
  <div className="hero" style={{ backgroundImage: `url(${image})` }}>
    {/* <div className="hero"> */}
{/* {!!offer && <img src={offer} alt="" className="offer" />} */}
    {/* <div className="message">
            <h1>{message}</h1>
        </div> */}
    {/* <iframe
      width="100%"
      height="140%"
      style={{ marginTop: "-5%" }}
      src="https://www.youtube.com/embed/JsjiUV0v2AA"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; mute"
      allowfullscreen
    ></iframe> */}
    {/* <video autoPlay={true} loop="loop" muted="muted" preload="metadata" poster="https://www.exodus.co.uk/sites/exod/files/styles/exo_1600_900/public/hero-image/hero-image/images/jose-spain-large_19.jpg?t=1GJksc&amp;itok=GHI4TYS4">
            <source src="https://res.cloudinary.com/ultimathule/video/upload/v1558543002/hero_myndband.mp4" type="video/mp4" />
        </video> */}
  </div>
);

export default Hero;

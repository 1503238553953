import React, { Component } from 'react';
import { Review, Loader } from '../../components/ui';
import * as Analytics from '../../services/Analytics';
class AdventureReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trips: []
        }
    }

    componentDidMount() {
        Analytics.logPageView()
    }

    render() {
        const { adventure } = this.props;
        // const { trips } = this.state;
        if (!adventure) return <Loader />
        return (
            adventure.review.map((review, i) => review && <Review key={i} title={review.title} summary={review.summary} rating={review.rating} questions={review.answers && review.answers[0].answer} />)
        )
    }
}

export default AdventureReviews;
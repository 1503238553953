import React from 'react';
import './BackgroundFade.scss';

const BackgroundFade = ({ image }) => (
    <div className="backgroundFade">
        <div className="backgroundImage" style={{ backgroundImage: `url(${image})`}}/>
        <div className="fader"/>
    </div>
);

export default BackgroundFade;
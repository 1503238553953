import React, { Component } from 'react';
import * as Analytics from '../../../services/Analytics';
import { Button } from '../';

import { Cog } from '../Icons';
import './ContactForm.scss';

import * as emailjs from 'emailjs-com';

class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			number: '',
			message: '',
			error: '',

			success: false,
			loading: false,
		}
	}

	componentDidMount() {
		emailjs.init('user_BQetvFF05Lx0TULXmtZ6i');
	}

	submitForm(e) {
		e.preventDefault();

		if (!this.state.name.length)
			return this.setState({ error: 'Vantar nafn!' });
		if (!this.state.email.length)
			return this.setState({ error: 'Vantar netfang!' });
		if (!this.state.message.length)
			return this.setState({ error: 'Það eru engin skilaboð!' });

		const template_params = {
			'reply_to': this.state.email,
			'from_name': this.state.name,
			'from_number': this.state.number,
			'to_name': 'ferd@ultimathule.is',
			'message_html': this.state.message,
			'web_url': window.location.href,
		}

		this.setState({ loading: true });
		Analytics.trackLead();

		emailjs.send('default_service', 'almenn_fyrirspurn', template_params)
			.then(res => {
				this.setState({
					loading: false,
					name: '',
					email: '',
					number: '',
					message: '',
					error: '', success: true
				});
			}, function (err) {
				this.setState({
					loading: false,
					name: '',
					email: '',
					number: '',
					message: '',
					error: 'Villa kom upp, vinsamlegast reyndu síðar'
				});
			});
	}

	render() {
		const { title, subscript } = this.props;
		return (
			<div className='contactForm'>
				<h1>{title}</h1>
				<p>{subscript}</p>
				<form id='contact' name="contact" onSubmit={e => this.submitForm(e)}>
					<input type="hidden" name="form-name" value="the-name-of-the-html-form" />
					<div className='inputs'>
						<div className='input'>
							<label htmlFor='name'>Nafn *</label>
							<input
								id='name'
								type='text'
								value={this.state.name}
								onChange={e => this.setState({ name: e.target.value, error: '', success: false })}
								disabled={this.state.loading}
							/>
						</div>
						<div className='input'>
							<label htmlFor='email'>Netfang *</label>
							<input
								id='email'
								type='text'
								value={this.state.email}
								onChange={e => this.setState({ email: e.target.value, error: '', success: false })}
								disabled={this.state.loading}
							/>
						</div>
						<div className='input'>
							<label htmlFor='number'>Símanúmer</label>
							<input
								id='number'
								type='text'
								value={this.state.number}
								onChange={e => this.setState({ number: e.target.value, error: '', success: false })}
								disabled={this.state.loading}
							/>
						</div>
					</div>
					<div className='textarea'>
						<label htmlFor='message'>Hvaða ferðir eru þér á huga? *</label>
						<textarea
							id='message'
							value={this.state.message}
							onChange={e => this.setState({ message: e.target.value, error: '', success: false })}
							disabled={this.state.loading}
						></textarea>
					</div>
					{/*<div className="inputFile">
						<label htmlFor='attachment' className='fileUpload'>Bæta skrá í viðhengi</label>
						<input type='file' id='attachment'/>
					</div>*/}
					{this.state.error && <p className='error'>{this.state.error}</p>}
					{this.state.success && <p className='success'>Skilaboðin hafa verið send!</p>}
					<div className='buttonContainer'>
						<Button type='submit' disabled={this.state.error} invert>{this.state.loading ? 'Skilaboð eru að sendast' : 'Senda'}</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ContactForm;
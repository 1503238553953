import React from 'react';
import './FullContainer.scss';

const FullContainer = ({ primary, children, flex }) => (
    <div className={`fullContainer ${primary ? 'primary' : ''}`}>
        <div className={`container ${flex ? 'flex' : ''}`}>
            {children}
        </div>
    </div>
);

export default FullContainer;
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import { Earth } from '../Icons';

const Header = ({ logo, alt, action }) => (
    <div className="header">
        <div className="headerContainer">
            {/* <div className="floater" onClick={e => action()}>
                <img className="earth" src={Earth} alt="" />
                <h3>Ævintýri</h3>
            </div> */}
            <h3 className="left">
                {alt}
            </h3>
            <Link to='/'>
                <img className="headerLogo" src={logo} alt={alt} />
            </Link>
            <h3 className="right">
                567 8978
            </h3>
        </div>
    </div>
);

export default Header;
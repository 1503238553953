import axios from 'axios';
import { apiUrl } from './apiUrl';

export const get = (code) =>
    axios.get(`${apiUrl}/adventure`, { params: { code } })


export const query = (query, page, pageSize, order, difficulty, flightLimit) => {
    console.log(flightLimit);
    return axios.get(`${apiUrl}/adventure/query`, { params: { ...query, page, pageSize, order: JSON.stringify(order), difficulty, flightLimit } })
}

// Deprechiated
export const getByType = (type, page, order) =>
    axios.get(`${apiUrl}/adventure/type`, { params: { type, page, order: JSON.stringify(order) } })

export const getByRegion = (region, page, order) =>
    axios.get(`${apiUrl}/adventure/region`, { params: { region, page, order: JSON.stringify(order) } })



export const getTrips = code =>
    axios.get(`${apiUrl}/trip`, { params: { code } })

export const getBestSellers = () =>
    axios.get(`${apiUrl}/adventure/bestsellers`)
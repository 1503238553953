const allActivities = [
    // { name: 'multi-country holidays', icelandicName: 'fjölþjóðaferðir', },
    // { name: 'multi-country culture holidays', icelandicName: 'fjölþjóðleg menningarfrí', },
    { name: 'cycling', icelandicName: 'hjólreiðaferðir', },
    // { name: 'road cycling', icelandicName: 'road hjólreiðar', },
    // { name: 'drop bars', icelandicName: 'drop bars', },
    // { name: 'off-road cycling', icelandicName: 'off-road hjólreiðar', },
    // { name: 'self-guided cycling', icelandicName: 'sjálfleiðandi hjólreiðaferðir', },
    // { name: 'multi-country cycling holidays', icelandicName: 'fjölþjóðlegar hjólreiðaferðir', },
    // { name: 'food & drink', icelandicName: 'matur og drykkur', },
    // { name: 'festival holidays', icelandicName: 'hátíðardaga', },
    { name: 'culture', icelandicName: 'menningarferðir', },
    // { name: 'eclipse holidays', icelandicName: 'eclipse holidays', },
    // { name: 'natural wonders', icelandicName: 'náttúruundur', },
    // { name: 'cultural wonders', icelandicName: 'menningaundur', },
    // { name: 'multi-country', icelandicName: 'fjölþjóðlegar ferðir', },
    { name: 'walking & trekking', icelandicName: 'gönguferðir', },
    // { name: 'centre-based', icelandicName: 'miðstöðvar', },
    // { name: 'point-to-point', icelandicName: 'point-to-point', },
    // { name: 'summits', icelandicName: 'leiðtogafundi', },
    // { name: 'coastal walks', icelandicName: 'coastal walks', },
    // { name: 'desert treks', icelandicName: 'eyðimörkinni', },
    // { name: 'wildlife walks', icelandicName: 'dýralífsgöngur', },
    // { name: 'self-guided walking', icelandicName: 'sjálfgönguleiðsla', },
    // { name: 'multi-country walking holidays', icelandicName: 'multi-country walking holidays', },
    { name: 'winter holidays', icelandicName: 'vetrarferðir', },
    { name: 'cross-country skiing', icelandicName: 'gönguskíðaferðir', },
    // { name: 'northern lights', icelandicName: 'norðurljós', },
    // { name: 'snowshoeing', icelandicName: 'snjóþrúgur', },
    // { name: 'dogsledding', icelandicName: 'hundasleðaferðir', },
    // { name: 'winter mixed-activity', icelandicName: 'vetur blandaðri virkni', },
    // { name: 'winter culture', icelandicName: 'vetrarmenningarferðir', },
    { name: 'ski racing', icelandicName: 'skíðakeppnir', },
    { name: 'family', icelandicName: 'fjölskylduferðir', },
    { name: 'mixed-activity', icelandicName: 'fjölbreytilegar ferðir', },
    { name: 'wildlife holidays', icelandicName: 'dýralífsferðir', },
    // { name: 'walking', icelandicName: 'gönguferðir', },
    // { name: 'ages 5 - 8 years', icelandicName: 'á aldrinum 5 til 8 ára', },
    // { name: 'ages 9 - 12 years', icelandicName: 'á aldrinum 9 til 12 ára', },
    // { name: 'ages 13 - 16 years', icelandicName: 'á aldrinum 13-16 ára', },
    // { name: 'rafting', icelandicName: 'rafting', },
    // { name: 'canyoning', icelandicName: 'canyoning', },
    // { name: 'kayaking & canoeing', icelandicName: 'kajakferðir og ísklifur', },
    // { name: 'climbing', icelandicName: 'klifur', },
    // { name: 'zip line', icelandicName: 'zip line', },
    // { name: 'snorkelling', icelandicName: 'snorkl', },
    // { name: 'wildlife holidays', icelandicName: 'dýralífsfrí', },
    // { name: 'safaris', icelandicName: 'safarí', },
    // { name: 'photography', icelandicName: 'ljósmyndun', },
    // { name: 'bears', icelandicName: 'birnir', },
    // { name: 'polar wildlife', icelandicName: 'dýralífspólferðir', },
    // { name: 'primates', icelandicName: 'prímatar', },
    // { name: 'marine wildlife', icelandicName: 'sjávarlífsferðir', },
    // { name: 'birdwatching', icelandicName: 'fuglaskoðun', },
    // { name: 'big cats', icelandicName: 'stórir kettir', },
    // { name: 'multi-country wildlife holidays', icelandicName: 'multi-country wildlife holidays', },
    { name: 'polar', icelandicName: 'heimskautsferðir', },
    // { name: 'photographic departures', icelandicName: 'ljósmyndaferðir', },
    // { name: 'arctic', icelandicName: 'norðurheimskautið', },
    // { name: 'antarctica', icelandicName: 'suðurskautslandið', },
    // { name: 'polar marine and wildlife', icelandicName: 'polar marine and wildlife', },
    // { name: 'new cycling holidays', icelandicName: 'ný hjólreiðaferðir', },
    { name: 'cycling bestsellers', icelandicName: 'vinsælar hjólreiðaferðir', },
    // { name: 'new cultural holidays', icelandicName: 'ný menningarfrí', },
    { name: 'culture bestsellers', icelandicName: 'vinsælar menningarferðir', },
    // { name: 'new walking holidays', icelandicName: 'new walking holidays', },
    { name: 'walking & trekking bestsellers', icelandicName: 'vinsælar gönguferðir', },
    // { name: 'new winter holidays', icelandicName: 'ný vetrarfrí', },
    { name: 'winter bestsellers', icelandicName: 'vinsælar vetrarferðir', },
    // { name: 'new family holidays', icelandicName: 'ný fjölskyldufrí', },
    { name: 'family bestsellers', icelandicName: 'vinsælar fjölskylduferðir', },
    // { name: 'new mixed-activity holidays', icelandicName: 'nýtt blönduð frídagur', },
    { name: 'mixed-activity bestsellers', icelandicName: 'vinsælar fjölbreytilegar ferðir', },
    // { name: 'new wildlife holidays', icelandicName: 'ný dýralífsfrí', },
    { name: 'wildlife bestsellers', icelandicName: 'vinsælar dýralífsferðir', },
    // { name: 'new polar holidays', icelandicName: 'new polar holidays', },
    { name: 'polar bestsellers', icelandicName: 'vinsælar heimskautsferðir', },

];

export const translateToIs = expression => {
    let term = allActivities.find(a => a.name.toLowerCase() === expression.toLowerCase());
    return term ? term.icelandicName : expression;
}

export const get = () => allActivities;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Navigation } from '../components/ui';

const destinations = [
    {
        label: 'africa',
        icelandicLabel: 'Afríka',
        countries: [
            { label: 'Botswana', icelandicLabel: 'Botsvana' },
            { label: 'Cape Verde', icelandicLabel: 'Cape Verde' },
            { label: 'Djibouti', icelandicLabel: 'Djibouti' },
            { label: 'Egypt', icelandicLabel: 'Egyptaland' },
            { label: 'Ethiopia', icelandicLabel: 'Eþíópíu' },
            { label: 'Kenya', icelandicLabel: 'Kenýa' },
            { label: 'Lesotho', icelandicLabel: 'Lesótó' },
            { label: 'Madagascar', icelandicLabel: 'Madagaskar' },
            { label: 'Morocco', icelandicLabel: 'Marokkó' },
            { label: 'Rwanda', icelandicLabel: 'Rúanda' },
            { label: 'Namibia', icelandicLabel: 'Namibía' },
            { label: 'Seychelles', icelandicLabel: 'Seychelles' },
            { label: 'South Africa', icelandicLabel: 'Suður-Afríka' },
            { label: 'Sudan', icelandicLabel: 'Súdan' },
            { label: 'Tanzania', icelandicLabel: 'Tansanía' },
            { label: 'Swaziland', icelandicLabel: 'Svasíland' },
            { label: 'Uganda', icelandicLabel: 'Úganda' },
            { label: 'Zambia', icelandicLabel: 'Sambía' },
            { label: 'Zimbabwe', icelandicLabel: 'Simbabve' },
        ]
    },
    {
        label: 'asia',
        icelandicLabel: 'Asía',
        countries: [
            { label: 'Bhutan', icelandicLabel: 'Bútan' },
            { label: 'Cambodia', icelandicLabel: 'Kambódía' },
            { label: 'China', icelandicLabel: 'Kína' },
            { label: 'India', icelandicLabel: 'Indland' },
            { label: 'Indonesia', icelandicLabel: 'Indónesía' },
            { label: 'Japan', icelandicLabel: 'Japan' },
            { label: 'Kazakhstan', icelandicLabel: 'Kasakstan' },
            { label: 'Kyrgyzstan', icelandicLabel: 'Kirgisistan' },
            { label: 'Laos', icelandicLabel: 'Laos' },
            { label: 'Malaysia', icelandicLabel: 'Malasía' },
            { label: 'Maldives', icelandicLabel: 'Maldíveyjar' },
            { label: 'Mongolia', icelandicLabel: 'Mongólía' },
            { label: 'Myanmar (Burma)', icelandicLabel: 'Mjanmar (Búrma)' },
            { label: 'Nepal', icelandicLabel: 'Nepal' },
            { label: 'Pakistan', icelandicLabel: 'Pakistan' },
            { label: 'Philippines', icelandicLabel: 'Filippseyjar' },
            { label: 'Sri Lanka', icelandicLabel: 'Sri Lanka' },
            { label: 'Tajikistan', icelandicLabel: 'Tadsjikistan' },
            { label: 'Thailand', icelandicLabel: 'Taíland' },
            { label: 'Turkmenistan', icelandicLabel: 'Túrkmenistan' },
            { label: 'Uzbekistan', icelandicLabel: 'Úsbekistan' },
            { label: 'Vietnam', icelandicLabel: 'Víetnam' },
        ]
    },
    {
        label: 'europe',
        icelandicLabel: 'Evrópa',
        countries: [
            { label: 'Albania', icelandicLabel: 'Albanía' },
            { label: 'Armenia', icelandicLabel: 'Armenía' },
            { label: 'Austria', icelandicLabel: 'Austurríki' },
            { label: 'Azerbaijan', icelandicLabel: 'Aserbaídsjan' },
            { label: 'Bosnia & Herzegovina', icelandicLabel: 'Bosnía og Hersegóvína' },
            { label: 'Bulgaria', icelandicLabel: 'Búlgaría' },
            { label: 'Croatia', icelandicLabel: 'Króatía' },
            { label: 'Cyprus', icelandicLabel: 'Kýpur' },
            { label: 'Czech Republic', icelandicLabel: 'Tékkland' },
            { label: 'Estonia', icelandicLabel: 'Eistland' },
            { label: 'Finland', icelandicLabel: 'Finnland' },
            { label: 'France', icelandicLabel: 'Frakklandi' },
            { label: 'Georgia', icelandicLabel: 'Georgia' },
            { label: 'Germany', icelandicLabel: 'Þýskaland' },
            { label: 'Greece', icelandicLabel: 'Grikkland' },
            { label: 'Greenland', icelandicLabel: 'Grænland' },
            { label: 'Hungary', icelandicLabel: 'Ungverjaland' },
            { label: 'Iceland', icelandicLabel: 'Ísland' },
            { label: 'Ireland', icelandicLabel: 'Írland' },
            { label: 'Italy', icelandicLabel: 'Ítalía' },
            { label: 'Kosovo', icelandicLabel: 'Kosovo' },
            { label: 'Latvia', icelandicLabel: 'Lettland' },
            { label: 'Lithuania', icelandicLabel: 'Litháen' },
            { label: 'Macedonia', icelandicLabel: 'Makedónía' },
            { label: 'Malta', icelandicLabel: 'Möltu' },
            { label: 'Moldova', icelandicLabel: 'Moldavía' },
            { label: 'Montenegro', icelandicLabel: 'Svartfjallaland' },
            { label: 'Norway', icelandicLabel: 'Noregi' },
            { label: 'Poland', icelandicLabel: 'Pólland' },
            { label: 'Portugal', icelandicLabel: 'Portúgal' },
            { label: 'Romania', icelandicLabel: 'Rúmenía' },
            { label: 'Russia', icelandicLabel: 'Rússland' },
            { label: 'Serbia', icelandicLabel: 'Serbía' },
            { label: 'Slovakia', icelandicLabel: 'Slóvakía' },
            { label: 'Slovenia', icelandicLabel: 'Slóvenía' },
            { label: 'Spain', icelandicLabel: 'Spánn' },
            { label: 'Sweden', icelandicLabel: 'Svíþjóð' },
            { label: 'Switzerland', icelandicLabel: 'Sviss' },
            { label: 'Turkey', icelandicLabel: 'Tyrkland' },
            { label: 'Ukraine', icelandicLabel: 'Úkraína' },
            { label: 'United Kingdom', icelandicLabel: 'Bretland' },
        ]
    },
    {
        label: 'north america',
        icelandicLabel: 'Norður-Ameríka',
        countries: [
            { label: 'Canada', icelandicLabel: 'Kanada' },
            { label: 'United States', icelandicLabel: 'Bandaríkin' },
            { label: 'Mexico', icelandicLabel: 'Mexíkó' },
        ]
    },
    {
        label: 'central america',
        icelandicLabel: 'Mið-Ameríka',
        countries: [
            { label: 'Bermuda', icelandicLabel: 'Bermúda' },
            { label: 'Belize', icelandicLabel: 'Belís' },
            { label: 'Costa Rica', icelandicLabel: 'Kosta Ríka' },
            { label: 'Guatemala', icelandicLabel: 'Gvatemala' },
            { label: 'Cuba', icelandicLabel: 'Kúbu' },
            { label: 'Mexico', icelandicLabel: 'Mexíkó' },
            { label: 'Nicaragua', icelandicLabel: 'Níkaragva' },
            { label: 'Panama', icelandicLabel: 'Panama' },
        ]
    },
    {
        label: 'south america',
        icelandicLabel: 'Suður-Ameríka',
        countries: [
            { label: 'Argentina', icelandicLabel: 'Argentína' },
            { label: 'Bolivia', icelandicLabel: 'Bólivía' },
            { label: 'Brazil', icelandicLabel: 'Brasilía' },
            { label: 'Chile', icelandicLabel: 'Chile' },
            { label: 'Colombia', icelandicLabel: 'Kólumbía' },
            { label: 'Ecuador', icelandicLabel: 'Ekvador' },
            { label: 'Peru', icelandicLabel: 'Perú' },
            { label: 'Uruguay', icelandicLabel: 'Úrúgvæ' },
            { label: 'Venezuela', icelandicLabel: 'Venesúela' },
        ]
    },
    {
        label: 'middle east',
        icelandicLabel: 'Mið-Austurlönd',
        countries: [
            { label: 'Egypt', icelandicLabel: 'Egyptaland' },
            { label: 'Jordan', icelandicLabel: 'Jórdanía' },
            { label: 'Oman', icelandicLabel: 'Óman' },
        ]
    },
    {
        label: 'oceania',
        icelandicLabel: 'Eyjaálfa',
        countries: [
            { label: 'New Zealand', icelandicLabel: 'Nýja Sjáland' }
        ]
    },
    {
        label: 'polar',
        icelandicLabel: 'Heimskautin',
        countries: [
            { label: 'Antarctica', icelandicLabel: 'Suðurheimskautið' },
            { label: 'Arctic', icelandicLabel: 'Norðurheimskautið' }
        ]
    }
]

const activities = [
    {
        label: 'cross-country+skiing,ski+racing',
        icelandicLabel: 'Gönguskíðaferðir',
    },
    {
        label: 'walking%20%26%20trekking',
        icelandicLabel: 'Gönguferðir',
    },
    {
        label: 'cycling',
        icelandicLabel: 'Hjólaferðir',
    },
    {
        label: 'mixed-activity',
        icelandicLabel: 'Blandaðar ferðir',
    },
    {
        label: 'winter+holidays',
        icelandicLabel: 'Vetrarferðir',
    },
    {
        label: 'family',
        icelandicLabel: 'Fjölskylduferðir',
    },
    {
        label: 'culture',
        icelandicLabel: 'Menningarferðir',
    },
    {
        label: 'wildlife+holidays',
        icelandicLabel: 'Dýralífsferðir',
    },
    {
        label: 'polar',
        icelandicLabel: 'Heimskautaferðir',
    },
]

const top = ['Áfangastaðir', 'Tegund Ferðar']
class NavigationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topActive: top[0],
            middleActive: '',
            bottomContent: null,
        }
    }

    render() {
        const { topActive, middleActive, bottomContent } = this.state;

        return (
            <Navigation>
                <div className="navLevel">
                    {
                        top.map((t, i) => <h2 key={i} onClick={e => this.setState({ topActive: t, bottomContent: null })} className={topActive === t ? 'active' : ''}>{t}</h2>)
                    }
                </div>
                <div className="navLevel">
                    {
                        topActive === top[0] ?
                            destinations.map((continent, i) => <h3 key={i} className={middleActive === continent.icelandicLabel ? 'active' : ''} onClick={e => this.setState({ middleActive: continent.icelandicLabel, bottomContent: continent })}>{continent.icelandicLabel}</h3>)
                            :
                            activities.map((activity, i) => <Link to={`/leit?activities=${activity.label}`}>{activity.icelandicLabel}</Link>)
                    }
                </div>
                {
                    bottomContent &&
                    <div className="navLevel">
                        {bottomContent.countries.map((country, i) => <Link to={`/leit?regions=${country.label}`}>{country.icelandicLabel}</Link>)}
                    </div>
                }
            </Navigation>
        )
    }
}

export default NavigationPage;
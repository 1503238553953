import React from 'react';
import { Link } from 'react-router-dom';
import './SubNav.scss';

const SubNav = ({ items }) => (
    <div className='subNav'>
        {items && items.map((item, i) => (
            item.link ?
                <Link to={item.link} key={i}>
                    <h2 className={`${window.location.pathname === item.link ? 'active' : ''}`}>{item.label}</h2>
                </Link>
                :
                <a href={item.url} target="_blank" rel='noopener noreferrer' key={i}>
                    <h2 className={`${window.location.pathname === item.link ? 'active' : ''}`}>{item.label}</h2>
                </a>
        ))}
    </div>
);

export default SubNav;
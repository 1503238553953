import React from 'react';
import './Review.scss';

const Star = () => (
    <svg height="25" width="23" data-rating="2">
        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" />
    </svg>
)

const Stars = ({ rating }) => (
    <div className='stars'>
        {
            new Array(5).fill().map((t, i) =>
                <div key={i} className={`star ${((i + 1) <= rating) ? 'active' : ''}`}>
                    <Star />
                </div>
            )
        }
    </div>
)

const Review = ({ key, title, summary, rating, questions }) => (
    <div className="review" key={key}>
        <h3>{title}</h3>
        <Stars rating={Number(rating)} />
        <p>{summary}</p>
        <h4>Spurt & Svarað</h4>
        {
            questions && questions.map(q => 
                <div className="question">
                    <p>{q.q}</p>
                    <b>{q.a}</b>
                </div>
            )
        }
    </div>
);

export default Review;
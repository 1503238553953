import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

export const initGA = () => {
    console.log('GA init')
    ReactGA.initialize('UA-135156896-1')
}

export const initPixel = () => {
    console.log('Pixel init')
    ReactPixel.init('613048469478579');
}

export const logPageView = () => {
    console.log(`Logging pageview for ${window.location.pathname}`)
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
    ReactPixel.pageView();
}


// Later
export const logEvent = (category = '', action = '') => {
    if (category && action) {
        ReactGA.event({ category, action });
    }
}
export const logException = (description = '', fatal = false) => {
    if (description) {
        ReactGA.exception({ description, fatal })
    }
}

export const trackLead = () => {
    ReactPixel.track('Lead');
    ReactGA.event({
        category: 'send',
        action: 'event',
        label: 'User',
        nonInteraction: 'Submitted Contact Form',
    })
    window.dataLayer && window.dataLayer.push({ event: 'lead' });
}
const allGrades = [
    {
        label: "Multi-Level",
        icelandicLabel: "Breytilegt erfiðleikastig",
    },
    {
        label: "Leisurely",
        icelandicLabel: "Hægfara",
    },
    {
        label: "Leisurely / Moderate",
        icelandicLabel: "Hægfara / Miðlungs",
    },
    {
        label: "Moderate",
        icelandicLabel: "Miðlungs",
    },
    {
        label: "Moderate / Challenging",
        icelandicLabel: "Miðlungs / Krefjandi",
    },
    {
        label: "Challenging",
        icelandicLabel: "Krefjandi",
    },
    {
        label: "Challenging / Tough",
        icelandicLabel: "Krefjandi / Erfitt",
    },
    {
        label: "Tough",
        icelandicLabel: "Erfitt",
    },
    {
        label: "Tough/Tough+",
        icelandicLabel: "Erfitt / Erfitt+",
    },
    {
        label: "Tough+",
        icelandicLabel: "Erfitt+",
    },
]

export const translateToIs = expression => {
    let term = allGrades.find(a => a.name.toLowerCase() === expression.toLowerCase());
    return term ? term.icelandicName : expression;
}

export const getIndex = expression => {
    let index = null;
    allGrades.map((grade, i) => (grade.label === expression) && (index = i));
    return index;
}

export const getRange = (from, to) => {
    return allGrades.slice(from, to + 1);
}

export const get = () => allGrades;
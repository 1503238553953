import React, { Component } from "react";
// import { Album } from '../../components/ui';

import ImageGallery from "react-image-gallery";
// import highlightsConcept from '../../assets/img/highlights.svg';
import "../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import * as Analytics from "../../services/Analytics";

class AdventureOverview extends Component {
  componentDidMount() {
    Analytics.logPageView();
  }

  render() {
    const { adventure } = this.props;
    const images =
      adventure.images &&
      adventure.images.map((img) => {
        return {
          original: `https://res.cloudinary.com/dj0wwxv7z/ar_16:9,c_fill,e_sharpen,g_auto/${img}.jpg`,
          thumbnail: `https://res.cloudinary.com/dj0wwxv7z/ar_16:9,w_250/${img}.jpg`,
        };
      });
    return (
      <div>
        <div style={{ margin: "20px" }}>
          <h2>Ferðalýsing</h2>
          <p>{adventure.editorial[0]}</p>
        </div>
        <br />

        {adventure.mapfile[0] && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "rgba(0,0,0,0.02)",
            }}
          >
            <img
              src={adventure.mapfile[0]}
              alt="map file"
              style={{
                maxWidth: "100%",
                width: "400px",
                height: "auto",
                marginBottom: "50px",
                marginTop: "30px",
                objectFit: "contain",
              }}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              margin: "20px",
              width: "calc(33% - 40px)",
              minWidth: "320px",
            }}
          >
            <h2>Ágrip</h2>
            <p>
              {adventure.highlight[0].split("*").map((h, i) => (
                <p key={i}>{h}</p>
              ))}
            </p>
          </div>
          <div
            style={{
              margin: "20px",
              width: "calc(33% - 40px)",
              minWidth: "320px",
            }}
          >
            <h2>Upplýsingar</h2>
            <p>
              {adventure.profile[0].split("*").map((h, i) => (
                <p key={i}>{h}</p>
              ))}
            </p>
          </div>
          <div
            style={{
              margin: "20px",
              width: "calc(33% - 40px)",
              minWidth: "320px",
            }}
          >
            <h2>Hvað er innifalið</h2>
            <p>
              {adventure.holidaytype[0].included[0].split("*").map((h, i) => (
                <p key={i}>{h}</p>
              ))}
            </p>
          </div>
        </div>

        {images && <ImageGallery items={images} slideDuration={110} />}
      </div>
    );
  }
}

export default AdventureOverview;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Arrow } from "../Icons";
import "./AdventureList.scss";

const mainActivities = [
  { name: "cycling", icelandicName: "hjólreiðaferðir" },
  { name: "culture", icelandicName: "menningarferðir" },
  { name: "walking & trekking", icelandicName: "gönguferðir" },
  { name: "winter", icelandicName: "vetrarferðir" },
  { name: "family", icelandicName: "fjölskylduferðir" },
  { name: "mixed-activity", icelandicName: "fjölbreytilegar ferðir" },
  { name: "wildlife", icelandicName: "dýralífsferðir" },
  { name: "polar", icelandicName: "heimskautsferðir" },
];

const grades = [
  {
    label: "Multi-Level",
    icelandicLabel: "Valfrjálst",
  },
  {
    label: "Leisurely",
    icelandicLabel: "Hægfara",
  },
  {
    label: "Leisurely / Moderate",
    icelandicLabel: "Hægfara / Miðlungs",
  },
  {
    label: "Moderate",
    icelandicLabel: "Miðlungs",
  },
  {
    label: "Moderate / Challenging",
    icelandicLabel: "Miðlungs / Krefjandi",
  },
  {
    label: "Challenging",
    icelandicLabel: "Krefjandi",
  },
  {
    label: "Challenging / Tough",
    icelandicLabel: "Krefjandi / Erfitt",
  },
  {
    label: "Tough",
    icelandicLabel: "Erfitt",
  },
  {
    label: "Tough/Tough+",
    icelandicLabel: "Erfitt / Erfitt+",
  },
  {
    label: "Tough +",
    icelandicLabel: "Erfitt+",
  },
];

class AdventureList extends Component {
  render() {
    const { adventures, currency, view, landOnly } = this.props;

    let seen = [];
    let display = [];
    adventures
      .map(
        (adventure) =>
          adventure.images &&
          adventure.images.length &&
          adventure.images[0].split("/").pop()
      )
      .map((thumb) =>
        seen.includes(thumb)
          ? seen.push(thumb) && display.push(1)
          : seen.push(thumb) && display.push(0)
      );

    return (
      <div className={view === 1 ? "adventureList" : "adventureGrid"}>
        {adventures.map((adventure, i) => {
          let price;
          if (landOnly) {
            price = Math.round(adventure.prices.lowestLand);
          } else if (adventure.prices.lowestFlight) {
            price = Math.round(adventure.prices.lowestFlight);
          } else if (adventure.prices.lowestLand) {
            price = Math.round(adventure.prices.lowestLand);
          }
          console.log(adventure.code, price);
          let altPrice =
            price &&
            "ISK " +
              Math.round(currency * price)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          price =
            price &&
            "frá £" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          let flight;
          if (landOnly) {
            flight = false;
          } else {
            flight = !!adventure.prices.lowestFlight;
          }

          return (
            <Adventure
              link={{ pathname: `/adventure/${adventure.code}`, adventure }}
              title={adventure.name[0]}
              images={adventure.images}
              highlights={adventure.synopsis[0]}
              activities={adventure.activities[0].activity
                .filter((a) =>
                  mainActivities.map((b) => b.name).includes(a.toLowerCase())
                )
                .map(
                  (a) =>
                    mainActivities.find(
                      (b) => b.name.toLowerCase() === a.toLowerCase()
                    ).icelandicName
                )
                .join(", ")}
              guidance={
                adventure.activities[0].activity.find(
                  (a) =>
                    a.toLowerCase() === "self-guided" ||
                    a.toLowerCase().includes("self-guided")
                )
                  ? "Án leiðsögumanns"
                  : "Með leiðsögumanni"
              }
              price={price}
              altPrice={altPrice}
              flight={flight}
              grade={adventure.grade[0]}
              key={i}
              list={view === 1}
              activeImg={display ? display[i] : 0}
              length={
                adventure.holidaytype[1]
                  ? adventure.holidaytype[1].duration[0].period
                  : adventure.holidaytype[0].duration[0].period
              }
            />
          );
        })}
      </div>
    );
  }
}

class Adventure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImg: this.props.activeImg || 0,
      arrows: false,
    };
  }

  trimParagraph(p) {
    if (p.length > 115) {
      p = p.slice(0, 115);
      p = p + "...";
    }
    return p;
  }

  render() {
    const {
      link,
      title,
      images,
      highlights,
      activities,
      guidance,
      price,
      altPrice,
      grade,
      flight,
      length,
    } = this.props;
    const { activeImg, arrows } = this.state;

    let gradeComponent = [];
    let found = false;
    let icelandicGrade;
    for (let i = 0; i < grades.length; i++) {
      if (!found && (grades[i].label === grade || grade === "")) {
        found = true;
        icelandicGrade = grades[i].icelandicLabel;
      }
      gradeComponent.push(
        <span key={i} className={found ? "" : "active"}>
          {i + 1}
        </span>
      );
    }

    return (
      <div
        className="adventureItem"
        onMouseEnter={(e) => this.setState({ arrows: true })}
        onMouseLeave={(e) => this.setState({ arrows: false })}
      >
        <div
          className="adventureImage"
          style={{
            backgroundImage: images
              ? `url(https://res.cloudinary.com/dj0wwxv7z/w_500/${images[activeImg]}.jpg)`
              : null,
          }}
        />

        <div className="grade">
          {gradeComponent}
          <div className="scale">
            <p>Auðvelt</p>
            <p>Erfitt</p>
          </div>
        </div>

        {arrows && (
          <div className="arrows">
            <img
              src={Arrow}
              alt="Previous"
              className="left"
              onClick={(e) =>
                this.setState({
                  activeImg: activeImg > 0 ? activeImg - 1 : images.length - 1,
                })
              }
            />
            <img
              src={Arrow}
              alt="Next"
              className="right"
              onClick={(e) =>
                this.setState({
                  activeImg: activeImg < images.length - 1 ? activeImg + 1 : 0,
                })
              }
            />
          </div>
        )}
        <Link to={link}>
          <div className="contents">
            <h3>{title}</h3>
            <p>{this.trimParagraph(highlights)}</p>
            <p className="activities">
              {guidance}, {activities}
            </p>
          </div>
          <b className="price">{price}</b>
          <b className="altPrice">{altPrice}</b>
          {price && <b className="disclaimer">m.v. sölugengi dagsins</b>}

          {price && (
            <b className="flight">
              {!flight ? "*flug ekki innifalið" : "*með flugi frá London"}
            </b>
          )}
          {length && (
            <b className="length">
              {length}
              <p> dagar</p>
            </b>
          )}
          <button>skoða nánar</button>
        </Link>
      </div>
    );
  }
}

export default AdventureList;

import React from 'react';
import './SubHeader.scss';

const SubHeader = ({ title, subtitle }) => (
    <div className='subHeader'>
        <h1>{title}</h1>
        <p>{subtitle}</p>
    </div>
);

export default SubHeader;
import React from 'react';
import './FlightTable.scss';
import Moment from 'moment';

const FlightTable = ({ trips, flightInclusive, displayChildrice, onClick }) => (
    <div className="tableWrapper">
        <table className='flightTable' cellSpacing={0}>
            <thead>
                <tr>
                    <th><span>Frá<br /></span></th>
                    <th><span>Til<br /></span></th>
                    <th><span>Verð<br /></span></th>
                    {displayChildrice && <th><span>Verð f. börn<br /></span></th>}
                    <th><span>Eins manns herbergi</span></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    trips.map((trip, i) => {
                        let type;
                        if (flightInclusive) {
                            type = trip.triptype.find(t => t.type === 'Flight inclusive adult');
                        } else {
                            type = trip.triptype.find(t => t.type === 'Land only');
                        }
                        if (!type) return;

                        // const type = trip.triptype && trip.triptype[flightInclusive ? 0 : 1];
                        const dates = type.dates[0];
                        const fullprice = type.prices[0].price.find(p => p.currency === 'GBP').current[0];
                        const childprice = type.prices[0].price[0].children ? type.prices[0].price[0].children[0].child[0].childprice[0] : '';

                        let supplement = trip.singlesupplement && trip.singlesupplement[0].prices[0].price;
                        supplement = supplement && supplement.find(sup => sup.currency === 'GBP').current;

                        return (
                            <tr key={i}>
                                <td>
                                    <p>{Moment(dates.start).format('DD.MM.YYYY')}</p>
                                </td>
                                <td>
                                    <p>{Moment(dates.end).format('DD.MM.YYYY')}</p>
                                </td>
                                <td>
                                    <p>{fullprice ? '£' + fullprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ''}</p>
                                </td>
                                {
                                    displayChildrice &&
                                    <td>
                                        <p>{childprice ? '£' + childprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ''}</p>
                                    </td>
                                }
                                <td>
                                    <p>{supplement ? '£' + supplement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' aukalega' : ''}</p>
                                </td>
                                <td>
                                    {(trip.availability[0].guaranteed == 1) ? <b style={{ fontWeight: 600 }}>Brottför staðfest</b> : <p>Brottför ekki staðfest</p>}
                                </td>
                                <td>
                                    <button onClick={e => onClick(trip)}>Bóka ferð</button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    </div>
);

export default FlightTable;
import React, { Component } from 'react';
import { ItineraryListing, Loader } from '../../components/ui';
import * as Analytics from '../../services/Analytics';
class AdventureItinerary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trips: []
        }
    }
    
    componentDidMount() {
        Analytics.logPageView()
    }
    
    render() {
        const { adventure } = this.props;
        // const { trips } = this.state;
        if (!adventure) return <Loader />
        console.log(adventure.holidaytype[0].itineraries_long[0].itinerary[0]);
        const long = adventure.holidaytype[0].itineraries_long[0].itinerary;
        const short = adventure.holidaytype[0].itineraries[0].itinerary;
        return (
            short.map((trip, i) => <ItineraryListing key={i} day={trip.day} short={trip.action} long={long[i].action}/>)
        )
    }
}

export default AdventureItinerary;
import React, { Component } from "react"
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
} from "react-simple-maps"

import { Countries } from '../../services/translations';
import world from './world-50m.json';

const wrapperStyles = {
    width: "100%",
    maxWidth: 980,
    margin: "0 auto",
    position: 'relative',
    cursor: 'pointer',
}

const tooltipStyles = {
    position: 'absolute',
    top: '-45px',
    right: 0,
    padding: '10px 15px',
    backgroundColor: '#2CA5A0',
    color: 'white',
    textTransform: 'capitalize',
}

class BasicMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltip: '',
            showing: false,
        }
    }

    handleMouseEnter = (geography, evt) => {
        geography.properties.name && this.setState({ tooltip: geography.properties.name, showing: true, })
    }

    handleMouseLeave = (geography, evt) => {

    }

    handleClick(geography, evt) {
        console.log(`/leit?regions=${geography.properties.name}`);
        window.location.href = `/leit?regions=${geography.properties.name}`;
    }

    render() {
        return (
            <div style={wrapperStyles} onMouseLeave={e => this.setState({ tooltip: '' })}>
                {this.state.tooltip && <h3 style={tooltipStyles}>{Countries.translateToIs(this.state.tooltip)}</h3>}
                <ComposableMap
                    projectionConfig={{
                        scale: 205,
                        rotation: [-11, 0, 0],
                    }}
                    width={980}
                    height={551}
                    style={{
                        width: "100%",
                        height: "auto",
                    }}
                >
                    <ZoomableGroup center={[0, 20]} disablePanning>
                        <Geographies geography={world}>
                            {(geographies, projection) => geographies.map((geography, i) => geography.id !== "ATA" && (
                                <Geography
                                    key={i}
                                    geography={geography}
                                    projection={projection}
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseLeave}
                                    onClick={this.handleClick}
                                    style={{
                                        default: {
                                            fill: "#efefef",
                                            stroke: "#a7a7a7",
                                            strokeWidth: 0.5,
                                            outline: "none",
                                        },
                                        hover: {
                                            fill: "#2CA5A0",
                                            stroke: "#a7a7a7",
                                            strokeWidth: 0.75,
                                            outline: "none",
                                        },
                                        pressed: {
                                            fill: "#20635C",
                                            stroke: "#607D8B",
                                            strokeWidth: 0.75,
                                            outline: "none",
                                        },
                                    }}
                                />
                            ))}
                        </Geographies>
                    </ZoomableGroup>
                </ComposableMap>
            </div>
        )
    }
}

export default BasicMap
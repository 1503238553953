import React, { Component } from 'react';
import './SearchInput.scss';

class SearchInput extends Component {
    constructor(props) {
        super(props);
        this.handleKeyBoard = this.handleKeyBoard.bind(this);
        this.state = {
            focus: false
        }
    }
    componentWillMount() {
        document.addEventListener("keydown", this.handleKeyBoard);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyBoard);
    }

    // Event Handlers
    handleKeyBoard(e) {
        if (this.state.focus && e.key === "Enter") {
            this.props.onSubmit();
        }
    }

    render() {
        const { value, label, tag, placeholder, onChange, onSubmit } = this.props;

        return (
            <label
                className="searchInput"
                htmlFor={tag}
                onFocus={e => this.setState({ focus: true })}
                onBlur={e => {
                    onSubmit();
                    this.setState({ focus: false });
                }}
            >
                {label}
                <div className="inputContainer">
                    <input
                        type="text"
                        id={tag}
                        name={tag}
                        value={value}
                        placeholder={placeholder}
                        onChange={e => onChange(e.target.value)}
                        autoComplete={'off'}
                    />
                </div>
            </label>
        )
    }
}

export default SearchInput;
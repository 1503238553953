import React, { Component } from 'react';
import { SubHeader, Container, AdventureList, Button, Loader } from '../components/ui';

import { Adventures } from '../services/ultimathule-api';
import { Activities, Countries, Continents } from '../services/translations';

import { Grid, List } from '../components/ui/Icons';

import axios from 'axios';
import * as Analytics from '../services/Analytics';

class CategoryPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contentLoading: false,
            initialLoading: true,
            adventures: [],
            page: 0,
            count: 0,
            gbp: null,
            view: 0,
            order: '',
            param: '',
            orderQuery: { 'name': 1 }
        }
    }
    componentWillMount() {
        let queryType = this.props.location.pathname.split('/')[1];
        let param = this.props.match.params.query;
        this.setState({ queryType, param }, () => {
            this.loadAdventures();
        });

        axios.get('https://apis.is/currency/arion')
            .then(res => {
                this.setState({ gbp: res.data.results.find(c => c.shortName === 'GBP').value })
            })
    }

    componentWillReceiveProps(nextProps) {
        let queryType = nextProps.location.pathname.split('/')[1];
        let param = nextProps.match.params.query;

        console.log(this.state.param, param);

        if (this.state.param !== param) {
            this.setState({
                queryType,
                param,
                contentLoading: false,
                initialLoading: true,
                adventures: [],
                page: 0,
                count: 0,
                gbp: null,
                view: 0,
                order: '',
                orderQuery: { 'name': 1 }
            }, () => {
                this.loadAdventures();
                axios.get('https://apis.is/currency/arion')
                    .then(res => {
                        this.setState({ gbp: res.data.results.find(c => c.shortName === 'GBP').value })
                    })
            })
        }
    }

    loadMore() {
        const { page } = this.state;
        this.setState({ contentLoading: true, page: page + 1 }, () => this.loadAdventures())
    }

    loadAdventures() {
        const { count, page, adventures, queryType, orderQuery, param } = this.state;
        this.setState({ contentLoading: true })

        let query = (queryType === 'activity') ? Adventures.getByType(param, page, orderQuery) : Adventures.getByRegion(param, page, orderQuery);
        query.then(res => {
            const newAdventures = res.data.adventures;
            this.setState({
                adventures: adventures.concat(newAdventures),
                page: page,
                count: page ? count : res.data.count,
                contentLoading: false,
                initialLoading: false,
            });
        })
            .catch(err => {
                console.log(err);
            })
    }

    handleOrderChange = e => {
        let orderQuery;
        const order = e.target.value;
        switch (order) {
            case 'a':
                orderQuery = { 'name': 1 }
                break;
            case 'ar':
                orderQuery = { 'name': -1 }
                break;
            case 'p':
                orderQuery = { 'prices.lowestFlight': -1, 'prices.lowestLand': -1 }
                break;
            case 'pr':
                orderQuery = { 'prices.lowestFlight': 1, 'prices.lowestLand': 1 }
                break;
            default:
                break;
        }

        this.setState({
            order,
            orderQuery,
            page: 0,
            count: 0,
            adventures: []
        }, () => this.loadAdventures())
    }

    render() {
        const { adventures, count, page, gbp, queryType, view, order, contentLoading, initialLoading, param } = this.state;
        if (initialLoading) return <Loader />;

        return (
            <div>
                <SubHeader
                    title={(queryType === 'activity') ? Activities.translateToIs(param) : Countries.translateToIs(param) || Continents.translateToIs(param)}
                    subtitle={count ? `${count} slíkar ferðir standa til boða akkurat núna!` : 'Því miður fundust engar slíkar ferðir'}
                />
                <Container>
                    <label htmlFor="">
                        <select name="sortBy" id="" style={{ border: 'none' }} onChange={this.handleOrderChange}>
                            <option value="a" selected={"a" === order}>Stafrófsröð</option>
                            <option value="ar" selected={"ar" === order}>Öfug Stafrófsröð</option>
                            <option value="p" selected={"p" === order}>Verð ( Hæst fyrst )</option>
                            <option value="pr" selected={"pr" === order}>Verð ( Lægst fyrst )</option>
                        </select>
                    </label>
                    <img src={List} style={{ width: '20px', float: 'right', marginLeft: '10px', marginBottom: '20px', opacity: view ? 1 : 0.5, cursor: 'pointer' }} alt="" onClick={e => this.setState({ view: 1 })} />
                    <img src={Grid} style={{ width: '20px', float: 'right', marginLeft: '10px', marginBottom: '20px', opacity: view ? 0.5 : 1, cursor: 'pointer' }} alt="" onClick={e => this.setState({ view: 0 })} />
                    <AdventureList adventures={adventures} currency={gbp} view={view} />
                    {
                        (page < Math.floor(count / 9) && count !== 9) &&
                        <Button onClick={this.loadMore.bind(this)} loading={contentLoading}>
                            Sækja fleiri ferðir
                        </Button>
                    }
                </Container>
            </div>
        )
    }
}

export default CategoryPage;
import React from 'react';
import './Button.scss';

import { Cog } from '../Icons';

const Button = ({ type, onClick, loading, invert, children }) => (
    <div className={`buttonContainer ${invert ? 'invert' : ''}`}>
        <button type={type || 'click'} onClick={e => !loading && (type !== 'submit') && onClick()}>
            {
                loading ? <img src={Cog} alt="loading" /> : children
            }
        </button>
    </div>
);

export default Button;
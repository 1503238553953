import React, { Component } from 'react';
import { FlightTable, Loader } from '../../components/ui';
import { Adventures } from "../../services/ultimathule-api";
import Modal from 'react-modal';
import './adventureForm.scss';
import Moment from 'moment';
import * as Analytics from '../../services/Analytics';
import * as emailjs from 'emailjs-com';

const primary = '#2CA5A0';
const customStyles = {
    content: {
        width: '800px',
        maxWidth: 'calc(100vw - 40px)',
        margin: '0 auto',
        top: '40px',
        left: 0,
        botton: '40px',
        right: 0,
    },
    title: {
        color: primary,
    },
    input: {

    },
    tripinput: {
        width: '100%',
    }
}

class AdventureTrips extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trips: [],
            loading: true,
            flightInclusive: true,
            selectedTrip: null,

            u1: '',
            u2: '',
            u3: '',
            u4: '',
            u5: '',
            e1: '',
            e2: '',
            e3: '',
            v1: '',
            v2: '',
            v3: '',
            v4: '',
            a1: '',
            a2: '',
            a3: '',
            a4: '',

        }

        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        Analytics.logPageView()
        emailjs.init('user_BQetvFF05Lx0TULXmtZ6i');
    }

    componentWillMount() {
        const { adventure } = this.props;
        if (!adventure) return;
        Adventures.getTrips(adventure.code)
            .then(res => this.setState({ trips: res.data.trips.trip, loading: false }))
            .catch(err => this.setState({ trips: [], loading: false }))
    }

    bookTrip = trip => {
        const { adventure } = this.props;
        const { flightInclusive } = this.state;
        // console.log('booking trip');
        console.log(adventure);
        const dates = trip.triptype[flightInclusive ? 0 : 1].dates[0];
        this.setState({
            selectedTrip: trip,
            t1: adventure.code,
            t2: trip.code,
            t3: adventure.name[0],
            t4: flightInclusive ? 'Með flugi frá London' : 'Án allra fluga',
            t5: `Frá: ${Moment(dates.start).format('DD.MM.YYY')} til: ${Moment(dates.end).format('DD.MM.YYY')}`,
        });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    closeModal() {
        this.setState({ selectedTrip: null });
    }

    submitModal = e => {
        e.preventDefault();
        const template_params = {
            ...this.state,
            'reply_to': this.state.u5,
            'from_name': this.state.u1,
            'to_name': 'ferd@ultimathule.is',
        }
        console.log(template_params);
        this.setState({
            u1: '',
            u2: '',
            u3: '',
            u4: '',
            u5: '',
            e1: '',
            e2: '',
            e3: '',
            v1: '',
            v2: '',
            v3: '',
            v4: '',
            a1: '',
            a2: '',
            a3: '',
            a4: '',
            selectedTrip: '',
        })
        emailjs.send('default_service', 'ferd_fyrirspurn', template_params);
    }

    render() {
        const { adventure } = this.props;
        const { trips, flightInclusive, loading, selectedTrip } = this.state;

        if (!adventure || loading) return <Loader />
        if (!trips || trips[0] === '' || trips[0] === null) return <p>Því miður eru engar skráðar ferðir</p>
        console.log(selectedTrip);
        let dates = selectedTrip && selectedTrip.triptype[flightInclusive ? 0 : 1].dates[0];
        return (
            <div>
                <form>
                    <label htmlFor="flight">
                        Verð með flugi frá London
                        <input type="checkbox" id="flight" name="flight" checked={flightInclusive} onChange={e => this.setState({ flightInclusive: e.target.checked })} />
                    </label>
                </form>
                {trips.length && <FlightTable trips={trips} flightInclusive={flightInclusive} displayChildrice={adventure.activities[0].activity.includes('Family')} onClick={this.bookTrip.bind(this)} />}

                <Modal
                    isOpen={!!this.state.selectedTrip}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <h2 className="modalTitle">Bóka ferð</h2>
                    <p className="modalSubtitle">{adventure.name} </p>
                    <p className="modalSubtitle">({dates && `${Moment(dates.start).format('DD.MM.YYY')} - ${Moment(dates.end).format('DD.MM.YYY')}`})</p>
                    <p className="modalSubtitle">{flightInclusive ? 'Með flugi frá London' : 'Án flugs'} - £{selectedTrip && selectedTrip.triptype[flightInclusive ? 1 : 0].prices[0].price[0].current[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</p>
                    <br />

                    <button className="modalClose" onClick={this.closeModal}>hætta við</button>
                    <img className="tripImage" src={`https://res.cloudinary.com/ultimathule/${adventure.images[0]}.jpg`} alt="" />
                    <form className="tripForm" onSubmit={this.submitModal}>
                        {/* Almennar uppl. */}
                        <p className="subSection">Upplýsingar um farþega</p>
                        <label htmlFor="">
                            Fullt nafn (eins og það kemur fram á vegabréfi) *
                            <input required type="text" className="tripinput" value={this.state.u1} onChange={e => this.setState({ u1: e.target.value })} />
                        </label>
                        <label htmlFor="">
                            Kennitala *
                            <input required type="text" className="tripinput" value={this.state.u2} onChange={e => this.setState({ u2: e.target.value })} />
                        </label>
                        <label htmlFor="">
                            Heimilisfang *
                            <input required type="text" className="tripinput" value={this.state.u3} onChange={e => this.setState({ u3: e.target.value })} />
                        </label>
                        <label htmlFor="">
                            Farsímanúmer *
                            <input required type="text" className="tripinput" value={this.state.u4} onChange={e => this.setState({ u4: e.target.value })} />
                        </label>
                        <label htmlFor="">
                            Netfang *
                            <input required type="text" className="tripinput" value={this.state.u5} onChange={e => this.setState({ u5: e.target.value })} />
                        </label>

                        {/* uppl. um tengilið */}
                        <p className="subSection">Upplýsingar tengilið ( Emergency Contact )</p>
                        <label htmlFor="">
                            Nafn tengiliðs *
                            <input required type="text" className="tripinput" value={this.state.e1} onChange={e => this.setState({ e1: e.target.value })} />
                        </label>
                        <label htmlFor="">
                            Símanúmer tengiliðs *
                            <input required type="text" className="tripinput" value={this.state.e2} onChange={e => this.setState({ e2: e.target.value })} />
                        </label>
                        <label htmlFor="">
                            Hvernig tengist tengiliður farþega?
                            <input type="text" className="tripinput" value={this.state.e3} onChange={e => this.setState({ e3: e.target.value })} />
                        </label>

                        {/* uppl. um vegabref */}
                        <p className="subSection">Vegabréf</p>
                        <label htmlFor="">
                            Vegabréfsnúmer *
                            <input required type="text" className="tripinput" value={this.state.v1} onChange={e => this.setState({ v1: e.target.value })} />
                        </label>
                        <label htmlFor="">
                            Útgáfudagur Vegabréfs *
                            <input required type="text" className="tripinput" value={this.state.v2} onChange={e => this.setState({ v2: e.target.value })} />
                        </label>
                        <label htmlFor="">
                            Gildistími vegabréfs (ath. að vegabréf þarf að gilda í 6 mánuði eftir að ferð lýkur) *
                            <input required type="text" className="tripinput" value={this.state.v3} onChange={e => this.setState({ v3: e.target.value })} />
                        </label>
                        <label htmlFor="">
                            Hver gefur vegabréfið út (ef annar en Þjóðskrá Íslands)
                            <input type="text" className="tripinput" value={this.state.v4} onChange={e => this.setState({ v4: e.target.value })} />
                        </label>

                        {/* aðrar uppl. */}
                        <p className="subSection">Annað</p>
                        <label htmlFor="">
                            Sérstakar óskir varðandi mat (ofnæmi, óþol...)?
                            <textarea name="" id="" cols="30" rows="4" className="tripinput" value={this.state.a1} onChange={e => this.setState({ a1: e.target.value })}></textarea>
                        </label>
                        <label htmlFor="">
                            Lyf eða heilsufarstengd vandamál sem þú vilt að leiðsögumaðurinn úti viti af?
                            <textarea name="" id="" cols="30" rows="4" className="tripinput" value={this.state.a2} onChange={e => this.setState({ a2: e.target.value })}></textarea>
                        </label>

                        {/* hjólreiðauppl. */}
                        {
                            adventure.activities && adventure.activities[0].activity.includes('Cycling') &&
                            <div>
                                <p>Hjólreiðatengd atriði</p>
                                <label htmlFor="">
                                    Hæð farþega
                                    <input type="text" className="tripinput" value={this.state.a3} onChange={e => this.setState({ a3: e.target.value })} />
                                </label>
                                <label htmlFor="">
                                    Skóstærð farþega
                                    <input type="text" className="tripinput" value={this.state.a4} onChange={e => this.setState({ a4: e.target.value })} />
                                </label>
                            </div>
                        }
                        <div className="buttonGroup">
                            <button onClick={this.closeModal} className="fail">Hætta við</button>
                            <button type="submit" className="success">Klára bókun</button>
                        </div>
                    </form>
                </Modal >
            </div >
        )
    }
}

export default AdventureTrips;